import React, { useState } from "react";

function Accordion({ items, textBottom }) {
  // Стан для зберігання відкритого табу
  const [openTab, setOpenTab] = useState(0);

  // Функція для перемикання відкритого табу
  const toggleTab = (index) => {
    // Якщо натиснуто на відкритий таб, закриваємо його, інакше відкриваємо новий
    setOpenTab(openTab === index ? null : index);
  };

  return (
    <div className="col-lg-11 col-12 m-auto">
      <div className="accordion" id="accordionExample">
        {items.map((item, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${openTab === index ? "" : "collapsed"}`}
                type="button"
                onClick={() => toggleTab(index)} // Викликаємо toggleTab при натисканні
                aria-expanded={openTab === index ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                {item.title}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${openTab === index ? "show" : ""}`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">{item.content}</div>
            </div>
          </div>
        ))}

        <div className="text_bottom_accordion">
          {textBottom.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
