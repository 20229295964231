// src/pages/SomePage.js

import React from "react";
import Accordion from "../components/Accordion";

function SomePage() {
  const accordionItems = [
    {
      title: "Как часто обновляется статистика?",
      content:
        "Статистика обновляется каждый час.",
    },
    {
      title: "Что такое оценка канала?",
      content:
        "Оценка канала → комплекс сравнительных характеристик телеграм-канала и его тематики, относительно других участников рынка."
    },
    {
      title: "Что такое оценка поста?",
      content:
        "Оценка поста → комплекс сравнительных характеристик внутри собственных постов телеграм-канала."
    },
  ];

  const textBottom = [
    "Мы анализируем, сравниваем, и оцениваем статистику телеграм-каналов. Чтобы добавить свой канал - воспользуйтесь кнопкой «Добавить канал»",
  ];

  return (
    <Accordion items={accordionItems} textBottom={textBottom} />
  );
}

export default SomePage;
