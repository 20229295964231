import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import FAQ from './components/Faq';
import Footer from './components/Footer';
import ChannelsPage from './components/ChannelsPage'; // Ваш компонент для сторінки /main
import PostPage from './components/PostPage'
import SomePage from './components/AccordionPage'
import ChannelPage from './components/ChannelPage';
import Privacy from './components/Privacy';
import './style.css';
import NotFoundPage from './components/404';


const Channels = () => (
  <>
    <ChannelsPage />
    <FAQ />
    <Footer />
  </>
);

const Channel = () => (
  <>
    <ChannelPage />
    <FAQ />
    <Footer />
  </>
);

const Post = () => (
  <>
    <PostPage />
    <SomePage />
    <FAQ />
    <Footer />
  </>
);

const HomePage = () => (
  <>
    <Header title={"Telega.Press — Аналитика телеграм-каналов"} description={"Каталог Телеграм-каналов и чатов, статистика рекламных размещений Telega.Press. Более 50 000 телеграм-каналов с подробной статистикой."} />
    <Hero />
    <FAQ />
    <Footer />
  </>
);

const PrivacyPage = () => (
  <>
    <Header title={"Telega.Press — Аналитика телеграм-каналов"} description={"Каталог Телеграм-каналов и чатов, статистика рекламных размещений Telega.Press. Более 50 000 телеграм-каналов с подробной статистикой."} />
    <Privacy />
  </>
);

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="/channels" element={<Channels />} />
      <Route path="/:channelName/:postId" element={<Post />} />
      <Route path="/channel/:channelUserName" element={<Channel />} />
    </Routes>
  </Router>
);

export default App;
