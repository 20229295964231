import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'; // Для іконок стрілок
import { getPostsChannels, getChannel } from '../api/api'
import Breadcrumbs from './Breadcrumbs'
import moment from 'moment'
import SomePage from './AccordionPage';
import { DOMAIN } from '../const';
import Header from './Header'

const ChannelPage = () => {
  const [channels, setChannels] = useState([]);
  const [channel, setChannel] = useState({});
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('')
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Для пагінації
  const [dateRange, setDateRange] = useState([null, null]); // Діапазон дат
  const [sort, setSort] = useState({ field: '', order: '' }); // Поле та напрямок сортування
  const [searchTerm, setSearchTerm] = useState('');
  const [breadcrumbsData, setBreadcrumbsData] = useState([
    { label: 'Главная', url: '/' },
    { label: 'Каналы', url: '/channels' },
  ]);

  useEffect(() => {
    loadChannelData();
  }, []);

  const loadChannelData = async () => {
    const channelId = window.location.pathname.split('/channel/')[1];
    const chanellDb = await getChannel(channelId);
    setChannel(chanellDb);
    setBreadcrumbsData((prev) => [...prev, { label: chanellDb.channelTitle, url: null }]);
    setTitle(chanellDb.channelTitle);
    fetchChannels(0, dateRange, sort, searchTerm);
  };

  const DateDisplay = ({ date }) => {
    const formattedDate = moment(date).format('DD/MM/YYYY'); // змініть формат на свій
    return <div>{formattedDate}</div>;
  };
  function truncateText(text, maxLength = 40) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  const handleDate = async (dates) => {
    alert('handle')
    setDateRange(dates)
    setPage(0)
    if (dateRange[0] && dateRange[1]) {
      fetchChannels(0, dateRange, sort, searchTerm)
    }
  }


  const fetchChannels = async (page, dateRange, sort, searchTerm = '') => {
    setLoading(false);
    // Формування параметрів запиту
    const query = new URLSearchParams();
    query.append('page', page);
    if (dateRange[0]) query.append('startDate', dateRange[0].toISOString());
    if (dateRange[1]) query.append('endDate', dateRange[1].toISOString());
    query.append('sortField', sort.field);
    query.append('sortOrder', sort.order);
    if (searchTerm) query.append('search', searchTerm);

    const data = await getPostsChannels(window.location.pathname.split('/channel/')[1], query.toString())

    if (page === 0) {
      setChannels([]); // Очищаємо лише для першої сторінки (під час нового пошуку)
    }
    setChannels((prevChannels) => [...prevChannels, ...data.posts]);
    setHasMore(data.hasMore); // Визначаємо, чи є ще канали
    setLoading(true);
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return '';
    return number.toLocaleString('en-US');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Скидаємо сторінку до 1 при новому пошуку
    fetchChannels(0, dateRange, sort, event.target.value); // Завантажуємо дані з новим текстом пошуку
  };
  const handleSort = (field) => {
    const newSort = {
      field,
      order: sort.field === field && sort.order === 'asc' ? 'desc' : 'asc'
    };
    setSort(newSort);
    setPage(0)
    fetchChannels(0, dateRange, newSort, searchTerm); // Скидаємо сторінку при новому сортуванні
  };

  const loadMoreChannels = () => {
    setPage(page + 1); // Збільшуємо сторінку для завантаження наступних елементів
  };

  return (
    <div>
      <Header
        keywords={`аналитика телеграм канала ${channel.channelUserName}, статистика канала и рекламы`}
        title={`Телеграм-канал ${channel.channelUserName} — @${channel.channelUserName} — Telega.press`}
        description={`Телеграм-Канал: ${channel.about} — Telega.Press`}
      />

      <Breadcrumbs breadcrumbs={breadcrumbsData} />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head_page d-lg-flex align-items-lg-end justify-content-lg-between ">
                <div className="description_page">
                  <h1 className="title title_small">
                    {title}
                  </h1>

                </div>
                <div id="export-button-container"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="filter_search d-flex">
                <input
                  type="text"
                  className="form-control"
                  id="search_table"
                  placeholder="Найти"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="date-picker-container d-flex align-items-center ms-3">
                  <DatePicker
                    selected={dateRange[0]}
                    onChange={(dates) => handleDate(dates)} // dates буде масив з [startDate, endDate]
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    selectsRange
                    isClearable
                    placeholderText="Выбрать диапазон дат"
                    className="form-control"
                  />
                </div>
              </div>
              <table id="channelTable" className="table_no_borders">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('textMessage')}>
                      Заголовок
                      {sort.field === 'textMessage' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('date')}>
                      Дата
                      {sort.field === 'date' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('views')}>
                      Просмотров
                      {sort.field === 'views' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('forwards')}>
                      Репостов
                      {sort.field === 'forwards' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('rating')}>
                      Оценка поста
                      {sort.field === 'rating' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && channels.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <img src="/img/spin.svg" alt="Loading..." className="spinner-border" />
                      </td>
                    </tr>
                  ) : (
                    channels.map((post, index) => (
                      <tr key={index}>
                        <td>
                          <div className="title_chalek">
                            <a href={`${DOMAIN}/${channel.channelUserName}/${post._id}`}>{truncateText(post.textMessage, 30)}</a>
                          </div>
                        </td>
                        <td>{DateDisplay({ date: post.date })}</td>
                        <td>
                          {formatNumberWithCommas(post.views)}
                        </td>
                        <td>{formatNumberWithCommas(post.forwards)}</td>
                        <td>{post.rating || 0}/10</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Кнопка "Показати ще" */}
              {hasMore && (
                <div className="text-center my-3">
                  <button id="showMore" variant="primary" onClick={loadMoreChannels}>
                    Показать еще
                  </button>
                </div>
              )}
            </div>

            <SomePage />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ChannelPage;
