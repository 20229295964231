import React from 'react';

const FAQItem = ({ id, question, answer }) => (
  <div className="accordion-item">
    <h2 className="accordion-header" id={`heading${id}`}>
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="true" aria-controls={`collapse${id}`}>
        {question}
      </button>
    </h2>
    <div id={`collapse${id}`} className="accordion-collapse collapse show" aria-labelledby={`heading${id}`} data-bs-parent="#faq">
      <div className="accordion-body">
        {answer}
      </div>
    </div>
  </div>
);

const FAQ = () => (
  <div className="faq faq_hero">
    <div className="container">
      <div className="row">
        <div className="col-lg-11 col-12 m-auto">
          <h2 className="title_section">Вопросы - ответы</h2>
          <p className="desc_section">Everything you need to know about the product and billing.</p>
        </div>
        <div className="col-11 m-auto">
          <div className="accordion accordion-faq" id="faq">
            <FAQItem id="faq1" question="Как добавить телеграм-канал?" answer="Пожалуйста, воспользуйтесь телеграм-ботом @telegapressadd" />
            <FAQItem id="faq2" question="Как скачать статистику в Excel?" answer="This is the first item's accordion body. It is hidden by default..." />
            <FAQItem id="faq3" question="У вас есть api?" answer="This is the first item's accordion body. It is hidden by default..." />
            <FAQItem id="faq4" question="Подбираете ли вы телеграм-каналы для рекламы?" answer="This is the first item's accordion body. It is hidden by default..." />
            <FAQItem id="faq5" question="Можно заказать кастомный отчет?" answer="This is the first item's accordion body. It is hidden by default..." />
          </div>
          <div className="col-12">
            <img src="img/mokup.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FAQ;
