import React, { useState, useEffect } from 'react';
import Breadcrumbs from './Breadcrumbs'
import { getPost } from '../api/api'
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Header from './Header'
import { DOMAIN } from '../const';

function PostContent({ post, channel }) {
  return (
    <div className="post_card">
      <div className="post_card_head">
        <img
          src={post.image}
          alt={`Аватарка телеграм-канала @${channel.channelUserName}`}
          title='Логотип'
          style={{ width: '120px', height: '150px', objectFit: 'cover' }}
        />
        <div className="post_card_head__info">
          <p className="m-0 title_card_post">{post.title}</p>
          <p className="m-0 create_post">{post.date}</p>
        </div>
      </div>
      <div className="content_post">
        <h2>{post.heading}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <img src={post.secondaryImage} alt="" />
      </div>
      <div className="author">
        Источник: <a href={post.sourceLink}>{post.sourceLink}</a>
      </div>
      <div className="post_navigation">
        {post.prev && (
          <a href={`${DOMAIN}/${channel.channelUserName}/${post.prev._id}`} className="btn_nav_post">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1L1 5L5 9"
                stroke="#5B677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{" "}
            Прошлый
          </a>
        )}

        {post.next && (
          <a href={`${DOMAIN}/${channel.channelUserName}/${post.next._id}`} className="btn_nav_post">
            Следующий{" "}
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5 5L1 1"
                stroke="#5B677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        )}
      </div>
    </div>
  );
}
function Sidebar({ stats, relatedPosts }) {
  return (
    <div className="sidebar_post">
      <div className="card_posr_static subscribers_post">
        <p className="m-0"> Всего <b>{stats.subscribers}</b> подписчиков</p>
        <p className="m-0">
          {stats.growth}
          <span>за день</span>
        </p>
      </div>
      <div className="card_posr_static indicators_post">
        <div className="wrap_indicators_post">
          {stats.indicators && stats.indicators.map((indicator, index) => (
            <div key={index} className="item_indicators_post">
              <div className={`indicators_post_title ${indicator.active ? 'active' : ''}`}>{indicator.title}</div>
              <div className="indicators_post_value">{indicator.value}</div>
              <div className={`indicators_post_raiting ${indicator.trend}`}>{indicator.trendPercentage}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="related_posts">
        <h3>Похожие посты</h3>
        {relatedPosts && relatedPosts.length > 0 ? (
          relatedPosts.map((post, index) => (
            <a href="#" key={index} className="item_related_posts">
              <p className="related_posts_title">{post.title}</p>
              <p className="m-0 related_posts_date">{post.date}</p>
            </a>
          ))
        ) : (
          <p>Нет похожих постов</p>
        )}
      </div>
    </div>
  );
}


function PostPage() {
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState({});
  const [breadcrumbsData, setBreadcrumbsData] = useState([
    { label: 'Главная', url: '/' },
    { label: 'Каналы', url: '/channels' },
  ]);

  const [post, setPost] = useState({});
  const [stats, setStats] = useState({});
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    getInfo();
  }, []);

  function truncateText(text, maxLength = 40) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  function removeEmojis(str) {
    return str.replace(
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE0F}\u{1F1E6}-\u{1F1FF}]/gu,
      '',
    );
  }

  function transText(text, firstLength, secondLength) {
    // Обрізаємо перші 65 символів і додаємо "..."
    let firstPart = text.slice(0, firstLength).trim();
    // Перевіряємо, чи не обрізаємо слово
    firstPart = firstPart.slice(0, firstPart.lastIndexOf(" ")) + '...';
    let secondPart = null

    if (text[firstLength]) {
      if (!text[firstLength].match(/\s/)) {
        // Залишок тексту після 65 символів
        let remainingText = text.slice(firstLength).trim();

        // Обрізаємо наступні 250 символів
        secondPart = remainingText.slice(0, secondLength).trim();

        // Переконуємось, що починаємо з цілих слів


        secondPart = secondPart.slice(secondPart.indexOf(" ")) + '...';
      }
    }


    return {
      firstPart,
      secondPart
    };
  }



  async function getInfo() {
    try {
      setLoading(false);

      const splited = window.location.pathname.split('/')
      const channelId = splited[splited.length - 2]
      const postId = splited[splited.length - 1]

      const { channel: channelLoad, messages, message, growth, nextPost, backPost } = await getPost(channelId, postId).catch((err) =>
        window.location.href = `${DOMAIN}/404`
      )

      setChannel(channelLoad)

      setBreadcrumbsData((prevBreadcrumbs) => [
        ...prevBreadcrumbs,
        { label: channelLoad.channelTitle, url: null },
      ]);

      const array = []

      for (const mess of messages) {
        array.push(
          { title: truncateText(mess.textMessage), date: DateDisplay({ date: mess.date }) }
        )
      }

      setRelatedPosts(array)

      const obj = {
        subscribers: formatNumberWithCommas(channel.participantsCount).toString(),
        growth: growth || 0,
        indicators: [
          { title: 'Оценка поста', value: '0/10', trend: '', trendPercentage: '', active: true },
          //   { title: 'Лайки', value: '1.231', trend: 'indicators_post_raiting_down', trendPercentage: '-47%' },
          { title: 'Просмотры', value: formatNumberWithCommas(message.views).toString(), trend: '', trendPercentage: '' },
          { title: 'Репосты', value: formatNumberWithCommas(message.forwards), trend: '', trendPercentage: '' },
        ],
      }

      setStats(obj)

      let deleteSmile = removeEmojis(message.textMessage)

      let titlePost = `${channelLoad.channelUserName}. `
      let title = `Пост телеграм канала @${channelLoad.channelUserName}`

      let content = message.messageHtml

      if (content === 'null') {
        content = message.textMessage
      }

      let needSymbol = 69 - titlePost.length

      const res = transText(deleteSmile, needSymbol, 240)

      const postObj = {
        _id: message._id,
        title: channelLoad.channelTitle,
        date: DateDisplay({ date: message.date }),
        heading: '',
        content,
        textMessage: res.secondPart || title,
        image: channelLoad.image,
        secondaryImage: message?.images[0] || '',
        sourceLink: `https://t.me/${channelLoad.channelUserName}/${message.messageId}`,
        next: nextPost,
        prev: backPost,
        titlePost: `${titlePost}«${res.firstPart}»`
      }

      setPost(postObj)
      setLoading(true);
    } catch (error) {
      alert(error)
    }
  }
  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return '';
    return number.toLocaleString('en-US');
  };

  const DateDisplay = ({ date }) => {
    return format(new Date(date), "dd MMM yyyy 'года в' HH:mm", { locale: ru });
  };


  return (
    <div>
      <Header
        keywords={`аналитика телеграм канала ${channel.channelUserName}, статистика поста №${post._id}`}
        description={post.textMessage}
        title={post.titlePost} />

      <Breadcrumbs breadcrumbs={breadcrumbsData} />
      <main>
        <div className="container">
          <h1 className="title title_small">Пост телеграм-канала {channel.channelTitle || ''}</h1>
          <div className="row">
            <div className="col-xl-8 col-lg-12">
              <PostContent post={post} channel={channel} />
            </div>
            <div className="col-xl-4 col-lg-12">
              <Sidebar stats={stats} relatedPosts={relatedPosts} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PostPage;
