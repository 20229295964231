// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
}

.h1-privacy{
  color: #343a40;
}

.p-privacy {
  color: #555;
  font-size: 1.1rem;
}

.section-privacy {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 15px;
}

.privacy-section:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Privacy.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".privacy-container {\n  background-color: #f9f9f9;\n  padding: 30px;\n  border-radius: 8px;\n}\n\n.h1-privacy{\n  color: #343a40;\n}\n\n.p-privacy {\n  color: #555;\n  font-size: 1.1rem;\n}\n\n.section-privacy {\n  border-bottom: 1px solid #e9ecef;\n  padding-bottom: 15px;\n}\n\n.privacy-section:last-child {\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
