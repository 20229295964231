import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DOMAIN } from '../const';
import { Helmet } from 'react-helmet';
import { API } from '../api/api'
import image from '../66.jpg'
import logo from '../54.jpg'


const Header = ({ keywords, title, description }) => (
  <>
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="robots" content="index, follow"></meta>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></meta>
      <link rel="canonical" href={`${window.location.href}`} />
      <meta property="og:url" content={`${window.location.href}`} />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>{title}</title>
      <meta name="description" content={`${description}`} />
      <meta property="og:site_name" content="Telega.Press" />
      <script type="application/ld+json">
        {`
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Telega.press",
          "url": ${DOMAIN},
          "logo": ${logo},
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+7-916-161-7239",
            "contactType": "Customer Service"
          }
        `}
      </script>
      <meta property="og:image" content={image}></meta>
      <meta name="keywords" content={keywords}></meta>
      <script type="text/javascript">
        {`
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(97465705, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true
          });
        `}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-2V5ECLH035"></script>
      <script>
        {
          `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-2V5ECLH035');`
        }
      </script>
    </Helmet>
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href={`${DOMAIN}`}>telega.press</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href={`${DOMAIN}/channels`}>Каталог</a>
              </li>
            </ul>
            <button
              className="btn btn_black"
              onClick={() => window.open('https://t.me/olyurevna_p', '_blank', 'noopener,noreferrer')}
            >
              Добавить канал
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 3.33334V12.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.3335 8H12.6668" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </header>
  </>
);

export default Header;
