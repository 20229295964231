import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Statistics from './components/Statistics';
import LogoBar from './components/LogoBar';
import FAQ from './components/Faq';
import Footer from './components/Footer';
import ChannelsPage from './components/ChannelsPage'; // Ваш компонент для сторінки /main
import PostPage from './components/PostPage'
import SomePage from './components/AccordionPage'
import ChannelPage from './components/ChannelPage';

import './style.css';


const Channels = () => (
  <>
    <Header />
    <ChannelsPage />
    <FAQ />
    <Footer />
  </>
);

const Channel = () => (
  <>
    <Header />
    <ChannelPage />
    <FAQ />
    <Footer />
  </>
);

const Post = () => (
  <>
    <Header />
    <PostPage />
    <SomePage />
    <FAQ />
    <Footer />
  </>
);

const HomePage = () => (
  <>
    <Header />
    <Hero />
    <FAQ />
    <Footer />
  </>
);

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/channels" element={<Channels />} />
      <Route path="/:channelName/:postId" element={<Post />} />
      <Route path="/channel/:channelUserName" element={<Channel />} />

    </Routes>
  </Router>
);

export default App;
