import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import '../style.css';

const Card = ({ title, number, percentage, imgSrc, className }) => {
  useEffect(() => {
    const chart = echarts.init(document.getElementById(className));

    const options = {
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value',
        max: 90,
        show: false
      },
      series: [
        {
          name: 'Дані',
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: className === 'card_like' ? '#F04438' : className === 'card_like2' ? '#12B76A' : '#F04438',
          },
          areaStyle: {
            color: className === 'card_like' ? '#FEF3F2' : className === 'card_like2' ? '#ECFDF3' : '#FEF3F2',
            opacity: 1,
          },
          data: [0, 10, 30, 50, 21, 39, 60] // Можна змінити дані за потреби
        }
      ]
    };

    chart.setOption(options);

    window.addEventListener('resize', () => chart.resize());

    return () => {
      chart.dispose();
    };
  }, [className]);

  return (
    <div className="col-xl-4 col-lg-6 col-sm-12 col-md-6 mb-lg-3 mb-md-3">
      <div className="item_card">
        <div className="row justify-content-lg-between align-items-end">
          <div className="col-xl-8 col-lg-6 col-md-6 col-sm-8 col-12">
            <div className="item_card_contnet">
              <div className="title_card">{title}</div>
              <div className="static_number">{number}</div>
              <div className={`card_postion ${percentage > 0 ? 'card_postion_top' : 'card_postion_down'}`}>
                <img src={imgSrc} alt="" /> {Math.abs(percentage)}%
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-12">
            <div id={className} className="card_like"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Statistics = () => (
  <div className="card_statics">
    <div className="row justify-content-xl-between justify-content-lg-center">
      <Card title="Лайков" number="244 млн." percentage={-10} imgSrc="img/raiting-down.svg" className="card_like" />
      <Card title="Просмотров" number="48 млрд." percentage={20} imgSrc="img/raiting-up.svg" className="card_like2" />
      <Card title="Репостов" number="10 тыс." percentage={-10} imgSrc="img/raiting-down.svg" className="card_like3" />
    </div>
  </div>
);

export default Statistics;
