import React from 'react';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="col-12 wrap_f d-lg-flex justify-content-lg-between">
        <p className="copy_r">© telega.press</p>
        <ul className="f_menu">
          <li><a href="https://telega.press/privacy">Политика приватности</a></li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
