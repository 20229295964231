import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DOMAIN } from '../const';

const Header = () => (
  <header>
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href={`${DOMAIN}`}>telega.press</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href={`${DOMAIN}/channels`}>Каталог</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Услуги</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">Аналитика</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">FAQ</a>
            </li>
          </ul>
          <button className="btn btn_black">
            Добавить канал
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 3.33334V12.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.3335 8H12.6668" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  </header>
);

export default Header;
