export const DOMAIN = 'https://telega.press'

export const tags = {
  lingvistika: 'Лингвистика',
  karera: 'Карьера',
  poznavatelnoe: 'Познавательное',
  kursyigajdy: 'Курсы и гайды',
  sport: 'Спорт',
  modaikrasota: 'Мода и красота',
  medicina: 'Медицина',
  zdoroveifitnes: 'Здоровье и Фитнес',
  kartinkiifoto: 'Картинки и фото',
  softiprilozheniya: 'Софт и приложения',
  videoifilmy: 'Видео и фильмы',
  muzyka: 'Музыка',
  igry: 'Игры',
  edaikulinariya: 'Еда и кулинария',
  citaty: 'Цитаты',
  rukodelie: 'Рукоделие',
  semyaideti: 'Семья и дети',
  priroda: 'Природа',
  intereristroitelstvo: 'Интерьер и строительство',
  telegram: 'Telegram',
  instagram: 'Инстаграм',
  prodazhi: 'Продажи',
  transport: 'Транспорт',
  religiya: 'Религия',
  ezoterika: 'Эзотерика',
  darknet: 'Даркнет',
  bukmekerstvo: 'Букмекерство',
  'shok-kontent': 'Шок-контент',
  erotika: 'Эротика',
  dlyavzroslyx: 'Для взрослых',
  drugoe: 'Другое',
  blogi: 'Блоги',
  novostiismi: 'Новости и СМИ',
  yumorirazvlecheniya: 'Юмор и развлечения',
  texnologii: 'Технологии',
  ekonomika: 'Экономика',
  biznesistartapy: 'Бизнес и стартапы',
  kriptovalyuty: 'Криптовалюты',
  puteshestviya: 'Путешествия',
  'marketing, pr, reklama': 'Маркетинг, PR, реклама',
  psixologiya: 'Психология',
  dizajn: 'Дизайн',
  politika: 'Политика',
  iskusstvo: 'Искусство',
  pravo: 'Право',
  obrazovanie: 'Образование',
  knigi: 'Книги'
}