import React from 'react';
import Header from './Header';

const NotFoundPage = () => {
  return (
    <div>
      <Header keywords={''} title={''} description={''} />
      <h1>Страница не найдена</h1>
      <nav style={styles.roadMap}>
      </nav>
    </div>
  );
};

const styles = {
  header: {
    background: '#6f42c1',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    fontSize: '2em',
    textTransform: 'uppercase',
  },
  roadMap: {
    display: 'flex',
    gap: '6px',
    fontSize: '16px',
    color: '#7749f8',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    lineHeight: '100%',
    padding: '16px 17px',
  },
  homeLink: {
    margin: '0 5px',
  },
  link: {
    color: '#7749f8',
    textDecoration: 'none',
  },
};

export default NotFoundPage;