import axios from 'axios'
const API = 'https://9fine.ru'

export const getChannels = async (query) => {
  const response = await axios.get(`${API}/channel/channels?${query}`);
  return response.data;
};

export const getPostsChannels = async (channelUserName, query) => {
  const response = await axios.get(`${API}/channel/posts/${channelUserName}?${query}`, {

  });
  return response.data

}

export const getChannel = async (channelUserName) => {
  const response = await axios.get(`${API}/channel/${channelUserName}`, {
    params: {
      channelName: channelUserName
    }
  });

  return response.data

}

export const getPost = async (channelId, postId) => {
  const response = await axios.get(`${API}/channel/post/${channelId}/${postId}`);

  return response.data
}