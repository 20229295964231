// src/pages/SomePage.js

import React from "react";
import Accordion from "../components/Accordion";

function SomePage() {
  const accordionItems = [
    {
      title: "Accordion Item #1",
      content:
        "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.",
    },
    {
      title: "Accordion Item #2",
      content:
        "This is the second item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.",
    },
    {
      title: "Accordion Item #3",
      content:
        "This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.",
    },
  ];

  const textBottom = [
    "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.",
    "Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo.",
  ];

  return <Accordion items={accordionItems} textBottom={textBottom} />;
}

export default SomePage;
