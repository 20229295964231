import React from 'react';
import Statistics from './Statistics'; // Імпортуємо компонент Statistics
import LogoBar from './LogoBar';

const Hero = () => (
  <div className="hero">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Аналитика Telegram-каналов</h1>
          <h2>Обновляемая в реальном времени статистика Telegram-каналов,<br /> размещенной рекламы от лидеров
            российского рынка</h2>
        </div>
      </div>
      <Statistics />
      <LogoBar />
    </div>
  </div>


);

export default Hero;
