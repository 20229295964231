import React, { useState } from 'react';

// Компонент для кожного пункту аккордеону
const FAQItem = ({ id, question, answer, isOpen, toggle }) => (
  <div className="accordion-item">
    <h2 className="accordion-header" id={`heading${id}`}>
      <button
        className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
        type="button"
        onClick={() => toggle(id)}
        aria-expanded={isOpen}
        aria-controls={`collapse${id}`}>
        {question}
      </button>
    </h2>
    <div
      id={`collapse${id}`}
      className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
      aria-labelledby={`heading${id}`}
      data-bs-parent="#faq">
      <div className="accordion-body">
        {answer}
      </div>
    </div>
  </div>
);

// Основний компонент FAQ
const FAQ = () => {
  // Стан для відстеження відкритого елементу
  const [openId, setOpenId] = useState(null);

  // Функція для перемикання стану (відкрити/закрити)
  const toggle = (id) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <div className="faq faq_hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 col-12 m-auto">
            <h2 className="title_section">Вопросы - ответы</h2>
            <p className="desc_section">Ответы на часто задаваемые вопросы</p>
          </div>
          <div className="col-11 m-auto">
            <div className="accordion accordion-faq" id="faq">
              <FAQItem
                id="faq1"
                question="Как добавить телеграм-канал?"
                answer="Пожалуйста, воспользуйтесь кнопкой «Добавить-канал» на сайте."
                isOpen={openId === 'faq1'}
                toggle={toggle}
              />
              <FAQItem
                id="faq2"
                question="Как скачать статистику в excel?"
                answer="воспользуйтесь кнопкой «Скачать в excel» на сайте."
                isOpen={openId === 'faq2'}
                toggle={toggle}
              />
              <FAQItem
                id="faq3"
                question="У вас есть api?"
                answer="Да, свяжитесь с менеджером через «Добавить-канал», обсудим все условия."
                isOpen={openId === 'faq3'}
                toggle={toggle}
              />
              <FAQItem
                id="faq4"
                question="Подбираете ли вы телеграм-каналы для рекламы?"
                answer="Да, свяжитесь с менеджером через «Добавить-канал», обсудим все условия."
                isOpen={openId === 'faq4'}
                toggle={toggle}
              />
              <FAQItem
                id="faq5"
                question="Можно заказать кастомный отчет?"
                answer="Да, свяжитесь с менеджером через «Добавить-канал», обсудим все условия."
                isOpen={openId === 'faq5'}
                toggle={toggle}
              />
            </div>
            <div className="col-12">
              <img src="img/mokup.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
