import React from 'react';

const LogoBar = () => (
  <div className="logo_bar">
    <div className="row">
      <div className="col-12">
        <h3>Поставляем статистику на эксклюзивных условиях</h3>
      </div>
      <div className="col-12">
        <div className="wrap_pl_logo">
          <img src="img/pl1.svg" alt="" />
          <img src="img/pl2.svg" alt="" />
          <img src="img/pl3.svg" alt="" />
          <img src="img/pl4.svg" alt="" />
          <img src="img/pl5.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default LogoBar;
