import React, { useEffect, useState } from 'react';
import { Accordion, Table, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'; // Для іконок стрілок
import SomePage from './AccordionPage';
import Breadcrumbs from './Breadcrumbs'
import { getChannels } from '../api/api'
import { tags } from '../const'
import Header from './Header';

const ChannelsPage = () => {
  const [channels, setChannels] = useState([
  ]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Для пагінації
  const [dateRange, setDateRange] = useState([null, null]); // Діапазон дат
  const [sort, setSort] = useState({ field: '', order: '' }); // Поле та напрямок сортування
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchChannels(page, dateRange, sort, searchTerm);
  }, [page, dateRange, sort, searchTerm]);

  const breadcrumbsData = [
    { label: "Главная", url: "/" },
    { label: "Каналы", url: null },
  ];

  const fetchChannels = async (page, dateRange, sort, searchTerm = '') => {
    setLoading(false);
    // Формування параметрів запиту
    const query = new URLSearchParams();
    query.append('page', page);
    if (dateRange[0]) query.append('startDate', dateRange[0].toISOString());
    if (dateRange[1]) query.append('endDate', dateRange[1].toISOString());
    query.append('sortField', sort.field);
    query.append('sortOrder', sort.order);
    if (searchTerm) query.append('search', searchTerm);
    const data = await getChannels(query.toString())
    if (page === 0) {
      setChannels([]); // Очищаємо лише для першої сторінки (під час нового пошуку)
    }
    setChannels((prevChannels) => [...prevChannels, ...data.channels]);
    setHasMore(data.hasMore); // Визначаємо, чи є ще канали
    setLoading(true);
    // Виклик API для отримання каналів (заміни на свій API)
    // fetch(`/api/channels?${query.toString()}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setChannels((prevChannels) => [...prevChannels, ...data.channels]);
    //     setHasMore(data.hasMore); // Визначаємо, чи є ще канали
    //     setLoading(false);
    //   });
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return '';
    return number.toLocaleString('en-US');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Скидаємо сторінку до 1 при новому пошуку
    fetchChannels(0, dateRange, sort, event.target.value); // Завантажуємо дані з новим текстом пошуку
  };
  const handleSort = (field) => {
    const newSort = {
      field,
      order: sort.field === field && sort.order === 'asc' ? 'desc' : 'asc'
    };
    setSort(newSort);
    setPage(0)

    fetchChannels(0, dateRange, newSort, searchTerm); // Скидаємо сторінку при новому сортуванні
  };
  const handleTagClick = (tag) => {
    setSearchTerm(tag);
    fetchChannels(0, dateRange, sort, tag); // Загружаем данные с новым тегом
  };
  const loadMoreChannels = () => {
    setPage(page + 1); // Збільшуємо сторінку для завантаження наступних елементів
    fetchChannels(page, dateRange, sort, searchTerm)
  };

  const handleChannelClick = (channelId) => {
    window.location.href = `/channel/${channelId}`;
    // Перенаправление на страницу канала
  };

  return (
    <div>
      <Header
        keywords={"аналитика телеграм каналов, статистика каналов и рекламы"}
        title={"Каталог Telegram-каналов и чатов — Россия — Telega.press"}
        description={'Крупнейший каталог Телеграм-каналов Telega.Press. Бесплатная статистика телеграм постов и размещений, статистика по рекламе'}
      />
      <Breadcrumbs breadcrumbs={breadcrumbsData} />
      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head_page d-lg-flex align-items-lg-end justify-content-lg-between ">
                <div className="description_page">
                  <h1 className="title title_small">Популярные каналы</h1>
                  <h2 className="desc_page">Статистика телеграм-каналов</h2>
                </div>
                <div id="export-button-container"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="filter_search d-flex">
                <input
                  type="text"
                  className="form-control"
                  id="search_table"
                  placeholder="Найти"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="date-picker-container d-flex align-items-center ms-3">
                  <DatePicker
                    selected={dateRange[0]}
                    onChange={(dates) => setDateRange(dates)} // dates буде масив з [startDate, endDate]
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    selectsRange
                    isClearable
                    placeholderText="Выбрать диапазон дат"
                    className="form-control"
                  />
                </div>
              </div>
              <table id="channelTable" className="table_no_borders">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('channelUserName')}>
                      Название канала
                      {sort.field === 'channelUserName' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('participantsCount')}>
                      Подписчики
                      {sort.field === 'participantsCount' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('subscriberGrowth')}>
                      Динамика
                      {sort.field === 'subscriberGrowth' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('totalViews')}>
                      Просмотры
                      {sort.field === 'totalViews' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('viewGrowth')}>
                      Динамика
                      {sort.field === 'viewGrowth' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('totalForwards')}>
                      Репосты
                      {sort.field === 'totalForwards' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                    <th onClick={() => handleSort('rating')}>
                      Оценка канала
                      {sort.field === 'rating' && (
                        sort.order === 'asc' ? <FaArrowUp /> : <FaArrowDown />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && channels.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <img src="/img/spin.svg" alt="Loading..." className="spinner-border" />
                      </td>
                    </tr>
                  ) : (
                    channels.map((channel) => (
                      <tr key={channel.channelTitle} onClick={() => handleChannelClick(channel.channelUserName)}>
                        <td>
                          <a href="#" className="chanel">
                            <img src={channel.image} alt={`Аватарка телеграм-канала @${channel.channelUserName}`} title="Логотип"
                            />
                            <div className="title_chalek">
                              <h3>{channel.channelTitle}</h3>
                              <p className="m-0 tag_tg active">
                                {
                                  channel.tags ? (
                                    channel.tags.map((tag) => (
                                      <span key={tag} className="tag" onClick={() => handleTagClick(tag)}>
                                        {tags[tag]}
                                      </span>
                                    ))
                                  ) : (
                                    '' // Якщо тегів немає, відображаємо порожній рядок
                                  )}
                              </p>
                            </div>
                          </a>
                        </td>
                        <td>{formatNumberWithCommas(channel.participantsCount)}</td>
                        <td>
                          <span className={`dinamyc ${channel.subscriberGrowth > 0 ? 'dinamyc_top' : 'dinamyc_down'}`}>
                            {formatNumberWithCommas(channel.subscriberGrowth || 0)}
                          </span>
                        </td>
                        <td>{formatNumberWithCommas(channel.totalViews)}</td>
                        <td>
                          <span className={`dinamyc ${channel.viewGrowth > 0 ? 'dinamyc_top' : 'dinamyc_down'}`}>
                            {formatNumberWithCommas(channel.viewGrowth) || 0}
                          </span>
                        </td>
                        <td>{formatNumberWithCommas(channel.totalForwards)}</td>
                        <td>
                          <span >
                            {channel.rating || 0}/10
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Кнопка "Показати ще" */}
              {hasMore && (
                <div className="text-center my-3">
                  <button id="showMore" variant="primary" onClick={loadMoreChannels}>
                    Показать еще
                  </button>
                </div>
              )}
            </div>

            <SomePage />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ChannelsPage;
