import React from 'react';
import pl1 from '../img/pl1.svg';
import pl3 from '../img/pl3.svg';
import pl2 from '../img/pl2.svg';
import pl4 from '../img/pl4.svg';
import pl5 from '../img/pl5.svg';




const LogoBar = () => (
  <div className="logo_bar">
    <div className="row">
      <div className="col-12">
        <h3>Поставляем статистику на эксклюзивных условиях</h3>
      </div>
      <div className="col-12">
        <div className="wrap_pl_logo">
          <img src={pl1} alt="" />
          <img src={pl2} alt="" />
          <img src={pl3} alt="" />
          <img src={pl4} alt="" />
          <img src={pl5} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default LogoBar;
