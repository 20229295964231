import React from 'react';

const Footer = () => (
  <footer>
    <div class="container">
      <div class="col-12 wrap_f d-lg-flex justify-content-lg-between">
        <p class="copy_r">© telega.press</p>
        <ul class="f_menu">
          <li><a href="#">Terms</a></li>
          <li><a href="#">Privacy</a></li>
          <li><a href="#">Cookies</a></li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
