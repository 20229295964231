import React from "react";

function Breadcrumbs({ breadcrumbs }) {
  return (
    <div className="breadcrumbs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumbs_wrap_tag">
              <div className="breadcrumbs_wrap">
                {breadcrumbs.map((crumb, index) => (
                  <span key={index}>
                    {crumb.url ? (
                      <a href={crumb.url}>{crumb.label}</a>
                    ) : (
                      <span className="current_page active">{crumb.label}</span>
                    )}
                    {index < breadcrumbs.length - 1 && " / "}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumbs;
